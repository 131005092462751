import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { useListAppSettings } from 'circulator/hooks/appSettings/useListAppSettings';
import { useContinueProcedure } from 'circulator/hooks/procedure/useContinueProcedure';
import { ProcedureTimeLimitSettings } from 'common/types';
import { Procedure } from 'common/types/circulator';

function calculateIdleLimitRemainingMinutes(
    procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined,
    startTime: string,
    lastContinueAt: string | undefined
) {
    if (!procedureTimeLimitSettings) {
        return null;
    }

    if (!procedureTimeLimitSettings.idleStopLimitMinutes) {
        return null;
    }

    const startTimeDate = new Date(lastContinueAt ?? startTime);
    const currentTime = new Date();
    const diffInMs = currentTime.getTime() - startTimeDate.getTime();

    const minutes = Math.floor(diffInMs / (1000 * 60));

    let remainingMinutes = procedureTimeLimitSettings.idleStopLimitMinutes - minutes;

    if (remainingMinutes < 0) {
        remainingMinutes = 0;
    }

    return remainingMinutes;
}

function calculateHardLimitRemainingMinutes(procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined, startTime: string) {
    if (!procedureTimeLimitSettings) {
        return null;
    }

    if (!procedureTimeLimitSettings.hardStopLimitMinutes) {
        return null;
    }

    const startTimeDate = new Date(startTime);
    const currentTime = new Date();
    const diffInMs = currentTime.getTime() - startTimeDate.getTime();

    const minutes = Math.floor(diffInMs / (1000 * 60));

    let remainingMinutes = procedureTimeLimitSettings.hardStopLimitMinutes - minutes;

    if (remainingMinutes < 0) {
        remainingMinutes = 0;
    }

    return remainingMinutes;
}

function shouldShowContinueModal(
    procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined,
    startTime: string,
    lastContinueAt: string | undefined
) {
    const remainingMinutes = calculateIdleLimitRemainingMinutes(procedureTimeLimitSettings, startTime, lastContinueAt);

    if (remainingMinutes === null) {
        return false;
    }

    if (!procedureTimeLimitSettings?.confirmBeforeStopMinutes) {
        return false;
    }

    if (remainingMinutes <= procedureTimeLimitSettings.confirmBeforeStopMinutes) {
        return true;
    }

    return false;
}

function shouldShowHardLimitModal(
    procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined,
    startTime: string,
    lastContinueAt: string | undefined
) {
    const remainingMinutes = calculateHardLimitRemainingMinutes(procedureTimeLimitSettings, startTime);

    if (remainingMinutes === null) {
        return false;
    }

    // always show hard stop modal 5 minutes before stop
    if (remainingMinutes <= 5) {
        return true;
    }

    return false;
}

function getHourText(hours: number) {
    return `${hours} ${hours !== 1 ? 'hours' : 'hour'}`;
}

function getMinuteText(minutes: number) {
    return `${minutes} ${minutes !== 1 ? 'minutes' : 'minute'}`;
}

function getHourMinuteText(hours: number, minutes: number) {
    if (hours > 0) {
        return `${getHourText(hours)} ${minutes > 0 ? getMinuteText(minutes) : ''}`;
    } else {
        return getMinuteText(minutes);
    }
}

function humanReadableRunningDuration(startTime: string) {
    const startTimeDate = new Date(startTime);
    const currentTime = new Date();
    const diffInMs = currentTime.getTime() - startTimeDate.getTime();

    const hours = Math.floor((diffInMs % 86400000) / 3600000);
    const minutes = Math.floor((diffInMs % 3600000) / 60000);

    return getHourMinuteText(hours, minutes);
}

function humanReadableStopDuration(
    useIdleLimit: boolean,
    procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined,
    startTime: string,
    lastContinueAt: string | undefined
) {
    const remainingMinutes = useIdleLimit
        ? calculateIdleLimitRemainingMinutes(procedureTimeLimitSettings, startTime, lastContinueAt)
        : calculateHardLimitRemainingMinutes(procedureTimeLimitSettings, startTime);

    if (remainingMinutes === null) {
        return '';
    }

    return getMinuteText(remainingMinutes);
}

function humanReadableHardLimitDuration(procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined) {
    if (!procedureTimeLimitSettings?.hardStopLimitMinutes) {
        return '';
    }

    const hours = Math.floor(procedureTimeLimitSettings?.hardStopLimitMinutes / 60); // Get the integer number of hours
    const minutes = procedureTimeLimitSettings?.hardStopLimitMinutes % 60; // Get the remaining minutes

    return getHourMinuteText(hours, minutes);
}

function humanReadableHardLimitNote(procedureTimeLimitSettings: ProcedureTimeLimitSettings | null | undefined) {
    if (!procedureTimeLimitSettings?.hardStopLimitMinutes) {
        return '';
    }

    return `Also, this procedure has a hard limit of ${humanReadableHardLimitDuration(procedureTimeLimitSettings)}.`;
}

interface Props {
    procedure: Procedure;
}

export const StopLimitModal = ({ procedure }: Props) => {
    const { procedureTimeLimitSettings } = useListAppSettings();
    const [showContinueModal, setShowContinueModal] = useState(false);
    const [runningDurationMessage, setRunningDurationMessage] = useState('');
    const [stopDurationMessage, setStopDurationMessage] = useState('');
    const { continueProcedure } = useContinueProcedure(procedure.id);
    const [isContinuing, setIsContinuing] = useState(false);
    const [hardLimitNoteMessage, setHardLimitNoteMessage] = useState('');
    const [showHardLimitModal, setShowHardLimitModal] = useState(false);
    const [hardLimitDurationMessage, setHardLimitDurationMessage] = useState('');
    const [closedHardLimitModal, setClosedHardLimitModal] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRunningDurationMessage(humanReadableRunningDuration(procedure.startTime));
            setHardLimitNoteMessage(humanReadableHardLimitNote(procedureTimeLimitSettings));
            setHardLimitDurationMessage(humanReadableHardLimitDuration(procedureTimeLimitSettings));

            const shouldShowHardLimit = shouldShowHardLimitModal(procedureTimeLimitSettings, procedure.startTime, procedure.lastContinueAt);

            setStopDurationMessage(
                humanReadableStopDuration(!shouldShowHardLimit, procedureTimeLimitSettings, procedure.startTime, procedure.lastContinueAt)
            );

            if (shouldShowHardLimit) {
                if (!closedHardLimitModal) {
                    setShowHardLimitModal(true);
                } else {
                    setShowHardLimitModal(false);
                }

                setShowContinueModal(false);
            } else {
                setShowHardLimitModal(false);

                if (isContinuing) {
                    setShowContinueModal(false);
                } else {
                    setShowContinueModal(shouldShowContinueModal(procedureTimeLimitSettings, procedure.startTime, procedure.lastContinueAt));
                }
            }
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [closedHardLimitModal, isContinuing, procedure.lastContinueAt, procedure.startTime, procedureTimeLimitSettings]);

    const handleContinue = async () => {
        setIsContinuing(true);
        setShowContinueModal(false);

        try {
            await continueProcedure();
        } catch (e) {
            console.error(e);
        }

        setIsContinuing(false);
    };

    const handleHardLimitOK = async () => {
        setClosedHardLimitModal(true);
        setShowHardLimitModal(false);
    };

    return (
        <>
            <Dialog open={showContinueModal} aria-labelledby="continue-dialog-title" aria-describedby="continue-dialog-description">
                <DialogTitle id="continue-dialog-title">Continue Procedure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="continue-dialog-description" color="primary">
                        <Typography>
                            This procedure has been running for {runningDurationMessage} and will stop automatically in {stopDurationMessage}.
                        </Typography>
                        <br />
                        <Typography>If you would like the procedure to continue, please click the continue button below.</Typography>
                        <br />
                        <Typography variant="caption">
                            Note: It is recommended that procedure recordings not exceed 4 hours. Please end this procedure and start a new one or
                            select continue if you plan to end it soon. Thank you.
                            {hardLimitNoteMessage && (
                                <>
                                    <br />
                                    {hardLimitNoteMessage}
                                </>
                            )}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" size="medium" autoFocus onClick={handleContinue}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showHardLimitModal} aria-labelledby="hard-limit-dialog-title" aria-describedby="hard-limit-dialog-description">
                <DialogTitle id="hard-limit-dialog-title">Procedure Stopping</DialogTitle>
                <DialogContent>
                    <DialogContentText id="hard-limit-dialog-description" color="primary">
                        <Typography>
                            Procedure recordings have a hard limit of {hardLimitDurationMessage} and are automatically stopped when they reach this
                            limit.
                        </Typography>
                        <br />
                        <Typography>
                            This procedure has been running for {runningDurationMessage} and will stop automatically in {stopDurationMessage}. Please
                            end this procedure and start a new one if you need to continue.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" size="medium" autoFocus onClick={handleHardLimitOK}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
