export const config = {
    appSettings: {
        all: () => ['appSettings'] as const,
        list: () =>
            ({
                key: [...config.appSettings.all(), 'list'],
                path: `/app-settings`,
            } as const),
    },
    attendee: {
        all: () => ['attendee'] as const,
        lists: () => [...config.attendee.all(), 'lists'] as const,
        list: (procedureId: number) =>
            ({
                key: [...config.attendee.lists(), procedureId],
                path: `/procedures/${procedureId}/attendees`,
            } as const),
        primaries: () => [...config.attendee.all(), 'primary'] as const,
        primary: (procedureId: number | undefined) =>
            ({
                key: [...config.attendee.primaries(), procedureId],
                path: `/procedures/${procedureId}/attendees/primary`,
            } as const),
        update: (procedureId: number, attendeeId: number) =>
            ({
                path: `/procedures/${procedureId}/attendees/${attendeeId}`,
            } as const),
    },
    campaign: {
        all: () => ['campaign'] as const,
        list: () => ({
            key: [...config.campaign.all(), 'list'] as const,
            path: `/campaigns` as const,
        }),
        update: () =>
            ({
                path: (campaignId: number) => `/campaigns/${campaignId}`,
            } as const),
    },
    device: {
        all: () => ['device'] as const,
        detail: () =>
            ({
                key: [...config.device.all(), 'detail'],
                path: `/devices/me`,
            } as const),
        logins: () => [...config.device.all(), 'logins'] as const,
        login: (uuid: string) =>
            ({
                key: [...config.device.logins(), uuid],
                path: `/devices/login/${uuid}`,
            } as const),
    },
    procedure: {
        all: () => ['procedure'] as const,
        active: () =>
            ({
                key: [...config.procedure.all(), 'active'],
                path: `/procedures/me/active`,
            } as const),
        start: () =>
            ({
                path: `/procedures`,
            } as const),
        stop: (procedureId: number) =>
            ({
                path: `/procedures/${procedureId}`,
            } as const),
        continue: (procedureId: number) =>
            ({
                path: `/procedures/${procedureId}/continue`,
            } as const),
    } as const,
    user: {
        all: () => ['user'] as const,
        details: () => [...config.user.all(), 'detail'] as const,
        detail: (userId: string) =>
            ({
                key: [...config.user.details(), userId],
                path: `/users/${userId}`,
            } as const),
        me: () => [...config.user.all(), 'me'] as const,
    },
    tv: {
        all: () => ['tv'] as const,
        room: (roomNumber: number | undefined) =>
            ({
                key: [...config.tv.all(), 'room', roomNumber],
                path: `/procedures/room/${roomNumber}`,
            } as const),
    },
};
