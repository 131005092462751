import { Suspense } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, Typography } from '@mui/material';
import { StopLimitModal } from 'circulator/components/Procedure/StopLimitModal';
import { Chat } from '../../common/components/Chat/Chat';
import { CirculatorPlayerDrawer } from '../components/Procedure/CirculatorPlayerDrawer';
import { FeaturedUserPlayer } from '../components/Procedure/FeaturedUserPlayer';
import { ProcedureAttendeesPanel } from '../components/Procedure/ProcedureAttendeesPanel';
import { ProcedureSummary } from '../components/Procedure/ProcedureSummary';
import Header from '../components/UI/Header';
import { useStopProcedure } from '../hooks/procedure/useStopProcedure';

interface Props {
    procedure: any;
    reset: () => void;
    classes: any;
    user: any;
    primaryAttendee: any;
}

const LandscapeMode = ({ procedure, classes, user, primaryAttendee }: Props) => {
    const { stopProcedure } = useStopProcedure(procedure.id);

    const handleStop = async () => {
        await stopProcedure();
    };

    return (
        <>
            <Header hasProcedure={!!procedure.id} />
            <ProcedureSummary procedure={procedure} onStopProcedure={handleStop} />
            <CirculatorPlayerDrawer procedure={procedure} />
            <Grid container alignContent="center" justifyContent="center" style={{ height: 'calc(100vh - 150px)' }}>
                <Grid item xs={2} height={1}>
                    <div className={classes.root}>
                        <div className={classes.chatBox}>
                            <Chat procedure={procedure} user={user} isRoom={false} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={5} height={1}>
                    <div className={classes.root}>
                        {!primaryAttendee ? (
                            <div className={`${classes.shadeMain}`}>
                                <Typography align="center" className={classes.logoSubhead}>
                                    No moderator currently
                                </Typography>
                            </div>
                        ) : (
                            <FeaturedUserPlayer />
                        )}
                    </div>
                </Grid>
                <Grid item xs={5} height={1}>
                    <Suspense fallback={<Skeleton variant="rect" height={300} />}>
                        <ProcedureAttendeesPanel procedure={procedure} />
                    </Suspense>
                </Grid>
            </Grid>
            <StopLimitModal procedure={procedure} />
        </>
    );
};

export default LandscapeMode;
