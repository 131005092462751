import React, { Suspense } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { StopLimitModal } from 'circulator/components/Procedure/StopLimitModal';
import { ErrorBoundary } from 'react-error-boundary';
import { Chat } from '../../common/components/Chat/Chat';
import ErrorBoundaryFallback from '../../common/components/ErrorBoundary/ErrorBoundaryFallback';
import Panel from '../../common/components/UI/Panel';
import { CirculatorPlayerDrawer } from '../components/Procedure/CirculatorPlayerDrawer';
import { FeaturedUserPlayer } from '../components/Procedure/FeaturedUserPlayer';
import { ProcedureAttendeesPanel } from '../components/Procedure/ProcedureAttendeesPanel';
import { ProcedureSummary } from '../components/Procedure/ProcedureSummary';
import Header from '../components/UI/Header';
import { useStopProcedure } from '../hooks/procedure/useStopProcedure';

interface Props {
    panelClasses?: Record<string, string>;
    procedure: any;
    reset: () => void;
    classes: any;
    user: any;
    primaryAttendee: any;
}

const PortraitMode = ({ panelClasses, procedure, reset, classes, user, primaryAttendee }: Props) => {
    const { stopProcedure } = useStopProcedure(procedure.id);

    const handleStop = async () => {
        await stopProcedure();
    };

    return (
        <>
            <Panel elevation={0} classes={panelClasses}>
                <ErrorBoundary
                    onReset={reset}
                    fallbackRender={({ resetErrorBoundary }) => <ErrorBoundaryFallback resetErrorBoundary={resetErrorBoundary} />}
                >
                    <React.Suspense fallback={<Skeleton />}>
                        <Grid container alignContent="center" justifyContent="center">
                            <Grid item xs={3}>
                                <div className={classes.root}>
                                    <div className={classes.chatBox}>
                                        <Chat procedure={procedure} user={user} isRoom={false} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <div className={classes.root}>
                                    {!primaryAttendee ? (
                                        <div className={`${classes.shadeMain}`}>
                                            <Typography align="center" className={classes.logoSubhead}>
                                                No moderator currently
                                            </Typography>
                                        </div>
                                    ) : (
                                        <FeaturedUserPlayer />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </React.Suspense>
                </ErrorBoundary>
            </Panel>
            <Header hasProcedure={!!procedure.id} />
            <ProcedureSummary procedure={procedure} onStopProcedure={handleStop} />
            <Suspense fallback={<Skeleton variant="rect" height={300} />}>
                <ProcedureAttendeesPanel procedure={procedure} />
            </Suspense>
            <CirculatorPlayerDrawer procedure={procedure} />
            <StopLimitModal procedure={procedure} />
        </>
    );
};

export default PortraitMode;
