import { config } from 'circulator/hooks/config';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

export const useContinueProcedure = (procedureId: number) => {
    const { path } = config.procedure.continue(procedureId);
    const queryClient = useQueryClient();

    const cont = useMutation(() => Api.post({ path }), {
        onSuccess: async () => {
            await queryClient.refetchQueries(config.procedure.all());
        },
    });

    return {
        continueProcedure: cont.mutateAsync,
    };
};
