import { useMemo } from 'react';
import { config } from 'circulator/hooks/config';
import type { CampaignSettings, ProcedureTimeLimitSettings } from 'common/types';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.ListAppSettingsResponse;

export function useListAppSettings() {
    const { key, path } = config.appSettings.list();

    const result = useQuery(key, () => Api.get<Response>({ path }));

    const appSettings = result.data;
    const campaignSettings = useMemo(() => parseCampaignSettings(appSettings), [appSettings]);
    const procedureTimeLimitSettings = useMemo(() => parseProcedureTimeLimitSettings(appSettings), [appSettings]);

    return {
        appSettings,
        campaignSettings,
        procedureTimeLimitSettings,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}

function parseCampaignSettings(appSettings: Response | undefined): CampaignSettings | null | undefined {
    return JsonParseSafe(appSettings?.campaignSettings) as CampaignSettings;
}

function parseProcedureTimeLimitSettings(appSettings: Response | undefined): ProcedureTimeLimitSettings | null | undefined {
    return JsonParseSafe(appSettings?.procedureTimeLimitSettings) as ProcedureTimeLimitSettings;
}

function JsonParseSafe<T>(text: string | null | undefined): T | string | null | undefined {
    if (text === null || text === undefined) {
        return text;
    }
    try {
        return JSON.parse(text);
    } catch {
        return text;
    }
}
